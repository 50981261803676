import React from "react";
// import ModalVideo from "react-modal-video";

const VideoPopup = () => {
  // const [modalStatus, isOpen] = useState(false);
  return (
    <div
      className="dg__video__area"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + "/images/bg/8.jpg"})`
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="dg__video__inner">
              <h2>Why is DWA different with another coin in cryptocurrency?</h2>
              <p>
                Cryptocurrencies were primarily designed as payment systems. DWA Coin recognizes that decentralized blockchains could be leveraged to broader range of applications and services but is not prescriptive about what those services should be or how they should be built. By design, DWA Coin strips away unnecessary complexity in its core, leaving only the most successful components of its predecessors intact. As a result, DWA Coin functions like a low-level, foundational protocol: it defines the interfaces and operations required to operate a lightweight blockchain, a decentralized communication system, and a rapid transaction processing framework, allowing higher-order components to build on those features.
              </p>
              <p>
                DWA Coin make simple adjustments to account balances based on every Transactions instead of tracing sets of input or output credits. The core software does not support any form of scripting language. DWA Coin creates a foundation that does not limit the ways in which those transaction types can be used and does not create significant overhead for using them, by providing a set of basic, flexible transaction types that can quickly and easily be processed. This flexibility is further amplified by DWA Coin low resource and energy requirements, and its highly readable, highly organized object-oriented source.
              </p>
              {/* <div className="play__video">
                <ModalVideo
                  channel="youtube"
                  isOpen={modalStatus}
                  videoId="2X9eJF1nLiY"
                  onClose={() => isOpen(false)}
                />
                <button onClick={() => isOpen(true)} className="play__btn">
                  <img
                    src={process.env.PUBLIC_URL + "/images/about/play.png"}
                    alt="play icon"
                  />
                  Play Now
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoPopup;
