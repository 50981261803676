import React from "react";
// import { Link } from "react-router-dom";
const SecureTransaction = () => {
  return (
    <div className="dg__secure__transaction">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-7">
            <div className="dg__secure__inner">
              <h2>
                What is DWA?
              </h2>
              <p>
              DWA is a 100% proof-of-stake blockchain designed to expand the use of blockchain technology beyond the simple transfer of value. Its technology was an adoption of NXT blockchain. Our tech experts work their best to enhance its core features without sacrifice the core strengths, especially process transactions securely, quickly and efficiently.
              </p>
              <p>
              As a proof of stake blockchain, there is no mining involved in DWA’s chain. It also means the coin supply is static. The 1 billion coins initially created is all there will ever be.
              </p>
              <h2>
              Stable Token
              </h2>
              <p>
              DWA was designed for particularly focuses on facilitating the building of applications and the offering of financial services. It’s defined as digital currency backed by IDR (Indonesian Rupiah).
              </p>
              {/* <Link
                className="secure__btn dg__btn btn--trans"
                to={process.env.PUBLIC_URL + "/contact"}
              >
                apply For Wallet
              </Link> */}
            </div>
          </div>
          <div className="col-lg-5">
            <div className="dg__secure__thumb">
              <img
                src={process.env.PUBLIC_URL + "/images/about/2.png"}
                alt="secure images"
                style={{width:"595px"}}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecureTransaction;
