import React from "react";
import SectionTitleFour from "../ui/section-titles/SectionTitleFour";

const AboutContent = () => {
  return (
    <div className="about__dgtaka about--2 section-padding--xl">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-7 col-12 col-sm-12 col-md-12">
            <div className="dg__secure__inner">
              {/* section title */}
              <SectionTitleFour title="About DWA" />

              <p>
                DWA is started by developers and designers dedicated to encourage the usage of blockchain technology and cryptocurrencies. 
                DWA mission is to bring changes onto world economy by using blockchain and encouraging decentralized finance for everyone.
              </p>

              <p>
                DWA itself is stablecoin developed as a response to the price volatility experienced by traditional 
                cryptocurrencies whose utility as transactional tools are limited by unstable changes in market value
              </p>
            </div>
          </div>
          <div className="col-lg-5 col-12 col-sm-12 col-md-12 sm__mt--40 md__mt--40">
            <div className="dg__secure__thumb">
              <img style={{ width: '600px' }}
                src={process.env.PUBLIC_URL + "/images/about/6.png"}
                alt="secure images"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutContent;
