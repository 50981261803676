import React from "react";
import { Link } from "react-router-dom";

const MobileNavMenu = () => {
  return (
    <nav className="offcanvasNavigation" id="offcanvas-navigation">
      <ul>
        <li>
          <Link to={process.env.PUBLIC_URL + "/"}>HOME</Link>
        </li>
        {/* <li>
          <Link to={process.env.PUBLIC_URL + "/blog"}>NEWS</Link>
        </li> */}
        <li>
          <Link to={process.env.PUBLIC_URL + "/about"}>ABOUT US</Link>
        </li>
        <li>
          <Link to={process.env.PUBLIC_URL + "/privacy-terms"}>PRIVACY & TERMS</Link>
        </li>
        <li>
          <a href="https://github.com/dwacoin/dwa" target="_blank">Repository</a>
        </li>
        <li>
          <a href="https://explorer.dwa.network/" target="_blank">Explorer</a>
        </li>
        <li>
          <a href="https://whitepaper.dwa.network/" target="_blank">Whitepaper</a>
        </li>
        {/* <li>
          <Link to={process.env.PUBLIC_URL + "/contact"}>CONTACT</Link>
        </li> */}
        <li>
          <a href="https://api.dwa.network/" target="_blank">API</a>
        </li>
      </ul>
    </nav>
  );
};

export default MobileNavMenu;
