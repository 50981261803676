import React from "react";

const SoftwareDownload = () => {
  return (
    <div className="dg__software__area section-padding--tb bg--white">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
            <div className="dg__software__inner">
            <h2>
                What is DWA?
              </h2>
              <p>
              DWA is a 100% proof-of-stake cryptocurrency which designed to expand the use of blockchain technology beyond the simple transfer of value. Its technology is adopted from NXT blockchain.
              </p>
              <p>
              DWA is created to be an useful payment technology that gives users the benefits of cryptocurrency but more stable in terms of price.
              </p>
              <h2>
              Stable Coin
              </h2>
              <p>
              DWA was designed for particularly focuses on facilitating the building of applications and the offering of financial services. It’s defined as digital currency backed by IDR (Indonesian Rupiah).
              </p>
              {/* <ul className="avilable__device">
                <li>
                  <a
                    href={process.env.PUBLIC_URL + "/"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/images/icons/4.png"}
                      alt="images"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href={process.env.PUBLIC_URL + "/"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/images/icons/5.png"}
                      alt="images"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href={process.env.PUBLIC_URL + "/"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/images/icons/6.png"}
                      alt="images"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href={process.env.PUBLIC_URL + "/"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/images/icons/7.png"}
                      alt="images"
                    />
                  </a>
                </li>
              </ul> */}
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 col-12 sm__mt--40 md__mt--40">
            <div className="phone__images">
              <img
                src={process.env.PUBLIC_URL + "/images/about/3.png"}
                alt="phone images"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SoftwareDownload;
