import React, { useEffect, useState } from "react";
import axios from 'axios';

const BlogContent = () => {
  const [news, setNews] = useState([]);
  useEffect(() => {
    axios({
      method : 'GET',
      url : 'https://back2.multiwallet.dwa.network/api/news/fetchData',
      dataType : 'json'
    }).then(response => {
      setNews(response.data);
    })
  }, []);

  var resultNews = news.map((result, key) => {
    return(
      <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={key}>
        <article className="blog__3">
          <div className="thumb">
            <a href={result.Url} target={'_blank'}>
              <img
                src={result.Image}
                alt={result.Image}
              />
            </a>
          </div>
          <div className="content">
            <div className="bl__author">
              {/* <img src="images/blog/sm-img/1.png" alt="author images" /> */}
              {/* <div className="author__inner">
                <h6>Farhan</h6>
                <span>16 Feb, 2020</span>
              </div> */}
            </div>
            <h2>
              <a href={result.Url} target={'_blank'}>
                {result.Title}
              </a>
            </h2>
            <p>
              {result.SubTitle}
            </p>
          </div>
        </article>
      </div>
    )
  })
  
  return (
    <div className="dg__blog__area bg--white pt--110 pb--140">
      <div className="container">
        <div className="row">
          {/* Start Single Blog */}
          {resultNews}
          {/* End Single Blog */}
        </div>
        {/* <div className="row mt--40">
          <div className="col-lg-12">
            <ul className="dg__pagination d-flex">
              <li>
                <a href={process.env.PUBLIC_URL + "/"}>01</a>
              </li>
              <li>
                <a href={process.env.PUBLIC_URL + "/"}>02</a>
              </li>
              <li>
                <a className="active" href={process.env.PUBLIC_URL + "/"}>
                  03
                </a>
              </li>
              <li>
                <a href={process.env.PUBLIC_URL + "/"}>04</a>
              </li>
              <li>
                <a href={process.env.PUBLIC_URL + "/"}>05</a>
              </li>
              <li>
                <a href={process.env.PUBLIC_URL + "/"}>......</a>
              </li>
              <li>
                <a href={process.env.PUBLIC_URL + "/"}>10</a>
              </li>
            </ul>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default BlogContent;
