import React from "react";

const Content = () => {
  return (
    <div className="dg__blog__area bg--white pt--110 pb--140">
      <div className="container">
        <nav>
          <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <a class="nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">PRIVACY POLICY</a>
            <a class="nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">TERMS AND CONDITION</a>
          </div>
        </nav>
        <div class="tab-content" id="nav-tabContent">
          <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
            <br/>
            <div className="privacy text-justify">
              <h2>PRIVACY POLICY</h2>
              <p>The protection and confidentiality of your personal information is very important to us. Accordingly, DWA has established the following privacy policy:</p>
              <br/>

              <b><li>DWA Commitment</li></b>
              <p className="pl-4" style={{ lineHeight: '25px'}}>We are using your personal information in accordance with the relevant provisions of the personal data protection regulations. This privacy policy describes the collection, apply, storage and protection of your personal information. This applies to applications (mobile apps), all related websites and services from DWA regardless of how you access or use them.</p>
              
              <b><li>Scope and Consent</li></b>
              <p className="pl-4" style={{ lineHeight: '25px'}}>You accept this privacy policy when you register for, access, or use our products, services, content, features, technology or functionality offered on mobile apps, all related websites and services (collectively, the “DWA Services”. "). We may upload policy changes on this page periodically, the revised version will take effect on the effective date of publication. It is your responsibility to review this privacy policy as often as possible.
                  For the purposes of this privacy policy, we use the term “personal information” to describe information that can be associated with a particular individual and can be used to identify that individual. This privacy policy does not apply to information that is made anonymous so that it cannot identify certain users.
              </p>
              
              <b><li>Collection of Personal Information</li></b>
              <ol type="a">
                <li>
                  <p style={{ lineHeight: '25px'}}>We may collect personally identifiable information from users in a variety of ways, aggregating, but not limiting, when users visit our site, register on the site, and connect with other similar activities, services, features or resources that we provide. provide on our Site. Users may be asked to provide the appropriate name, and email address. Users may visit our site anonymously. We will collect personally identifiable information from users when they voluntarily submit such information to us. Users can always refuse to provide personally identifiable information, except that doing so may prevent them from engaging in certain Site-related activities.</p>
                </li>
                <li>
                  <p style={{ lineHeight: '25px'}}>We may collect non-personally identifiable information about users whenever they interact with our Site. Non-personal identification information may include browser name, computer type and technical information about users who are connected to our Site, such as the operating system and Internet service provider used and other similar information</p>
                </li>
              </ol>

              <b><li>Cookie Web Browser</li></b>
              <p className="pl-4" style={{ lineHeight: '25px'}}>Our site may use "cookies" to improve user experience. Users' web browsers place cookies on their hard drives for recording purposes and sometimes to track information about them. Users can choose to set their web browser to refuse cookies, or to notify you when a cookie is sent. If they do, note that some parts of the site may not function properly.</p>

              <b><li>How We Use Collected Information ?</li></b>
              <p className="pl-4" style={{ lineHeight: '25px'}}>DWA may collect and use User's personal information for the following purposes:</p>
              <ol type="1">
                <li>
                  <p style={{ lineHeight: '25px', marginBottom: '8px' }}>To process payments. We may use the information that Users provide about themselves when placing an order only to provide services according from the order. We do not share this information with outside parties except to the extent necessary to provide this service;</p>
                </li>
                <li>
                  <p style={{ lineHeight: '25px', marginBottom: '8px' }}>To run promotions, contests, surveys or other features on this site;</p>
                </li>
                <li>
                  <p style={{ lineHeight: '25px', marginBottom: '8px' }}>To send information to Users, they agree to receive on theme that we think are interest for them;</p>
                </li>
                <li>
                  <p style={{ lineHeight: '25px', marginBottom: '8px' }}>To send periodic emails;</p>
                </li>
                <li>
                  <p style={{ lineHeight: '25px', marginBottom: '8px' }}>
                  We may use email addresses to send users information and updates relating to their orders. It may also be used to respond to inquiries, questions and/or other requests. If a User decides to opt-in to our mailing list, they will receive an email which may contain company news, updates, related product or service information, etc. If at any time the User wishes to unsubscribe from receiving emails at a later date, we include detailed unsubscribe instructions at the bottom of each email.
                  </p>
                </li>
              </ol>

              <b><li>How We Protect User Personal Information ?</li></b>
              <p className="pl-4" style={{ lineHeight: '25px'}}>We implement appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of personal information, usernames, passwords, transaction information and data stored on our Site.</p>
              <p className="pl-4" style={{ lineHeight: '25px'}}>The exchange of sensitive and personal data between the Site and its Users occurs through a secure SSL communication channel and is encrypted and protected with a digital signature.</p>

              <b><li>Sharing User Information</li></b>
              <p className="pl-4" style={{ lineHeight: '25px'}}>We do not sell, trade or rent User's personally identifiable information to others. We may share generic aggregated demographic information that is not linked to any personally identifiable information about visitors and users with our business partners, trusted affiliates and advertisers for the purposes described above.</p>

              <b><li>Conversion of Privacy Policy</li></b>
              <p className="pl-4" style={{ lineHeight: '25px'}}>DWA has the discretion to update this privacy policy from time to time. When we do, we will revise the updated date at the bottom of this page. We encourage Users to check this page frequently to keep any changes informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to periodically review this privacy policy and become aware of any modifications.</p>

              <b><li>User Acceptance of these Terms</li></b>
              <p className="pl-4" style={{ lineHeight: '25px'}}>By using this Site, you signify your acceptance of these policies and terms of service. If you do not agree to this policy, please do not use our Site.</p>
            </div>
          </div>
          <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
            <br/>
            <div className="terms text-justify">
              <h2>TERMS AND CONDITION</h2>
              <p style={{ lineHeight: '25px'}}>Terms and Conditions for using the DWA site or application (hereinafter referred to as the “Terms and Conditions”) is an agreement between you (“user”) and DWA (“DWA” or “We”) that governs how you use the product, services, technology, and services provided by DWA including but not limited to services when a user registers an account through the https://dwa.network website (hereinafter referred to as the “DWA Site”). By registering as a user, the user has stated that the user has READ, UNDERSTAND, ACCEPT, and AGREE to all the terms and conditions contained in these Terms and Conditions and the Privacy Policy as follows:</p>

              <b><li>DEFINITION</li></b>
              <p className="pl-4" style={{ lineHeight: '25px'}}>As long as the context of the sentence does not specify otherwise, the terms or definitions in the terms and conditions have the following meanings:</p>
              <ol type="1">
                <li>
                  <p style={{ lineHeight: '25px', marginBottom: '8px' }}><b>DWA account</b> is an account or access granted to users to carry out activities using the DWA site;</p>
                </li>
                <li>
                  <p style={{ lineHeight: '25px', marginBottom: '8px' }}><b>DWA assets</b> are digital commodities, using cryptography, information technology networks, and distributed ledgers, to regulate the creation of new units, verify transactions, and secure transactions without interference from other parties, which are traded on DWA's website;</p>
                </li>
                <li>
                  <p style={{ lineHeight: '25px', marginBottom: '8px' }}><b>USDT</b> is a currency specifically designed to build an intermediary between traditional currencies and cryptocurrencies</p>
                </li>
                <li>
                  <p style={{ lineHeight: '25px', marginBottom: '8px' }}><b>Fund</b> is the amount deposited by the user in the form of USDT through interbank book-entry for the purposes of trading activities on the DWA website;</p>
                </li>
                <li>
                  <p style={{ lineHeight: '25px', marginBottom: '8px' }}><b>Blockchain</b> is a transaction recording system or database that is widely distributed in an online peer-to-peer network;</p>
                </li>
                <li>
                  <p style={{ lineHeight: '25px', marginBottom: '8px' }}><b>PIN</b> is a personal identification number to re-authenticate DWA users or to confirm activities carried out by users in DWA applications;</p>
                </li>
                <li>
                  <p style={{ lineHeight: '25px', marginBottom: '8px' }}><b>OTP</b> is a password that can be used only once to verify the user of the DWA application;</p>
                </li>
                <li>
                  <p style={{ lineHeight: '25px', marginBottom: '8px' }}><b>Password</b> is encryption that can protect DWA accounts with an alphanumeric combination consisting of uppercase and lowercase letters, numbers, and symbols;</p>
                </li>
                <li>
                  <p style={{ lineHeight: '25px', marginBottom: '8px' }}><b>Wallet</b> is a medium used to store funds or crypto assets in the form of coins or tokens;</p>
                </li>
                <li>
                  <p style={{ lineHeight: '25px', marginBottom: '8px' }}><b>Fund Placement</b> is the process of storing funds or crypto assets by users which refers to the process or top-up mechanism carried out through a DWA account;</p>
                </li>
                <li>
                  <p style={{ lineHeight: '25px', marginBottom: '8px' }}><b>Privacy Policy</b> is a provision that governs how DWA collects, uses, maintains, and discloses information collected from users through the DWA website;</p>
                </li>
                <li>
                  <p style={{ lineHeight: '25px', marginBottom: '8px' }}><b>Services</b> are services provided by DWA including but not limited to DWA asset trading activities;</p>
                </li>
                <li>
                  <p style={{ lineHeight: '25px', marginBottom: '8px' }}><b>User</b> is a person (individual) who owns a DWA account who has registered on the DWA website, thus obtaining the authority from DWA to carry out crypto asset trading activities.</p>
                </li>
              </ol>

              <b><li>REGISTRATION PROCESS</li></b>
              <ol type="1">
                <li>General requirements. To use the service, you must be registered as a user with the following conditions:</li>
                <ul className="dashed">
                  <li> Has reached the age of 18 (eighteen) years;</li>
                  <li> Have an Identity Card (KTP) for Indonesian citizens, or a passport issued by the user's country of origin and/or a Temporary Stay Permit Card (KITAP) or a Limited Stay Permit Card (KITAS) for foreign nationals;</li>
                  <li> Using own funds or crypto assets and not crypto funds or assets originating or belonging to others, or proceeds of criminal acts, money laundering, terrorism financing and/or weapons of mass destruction;</li>
                  <li> Register through the DWA website;</li>
                  <li> Agree and agree to comply with the Terms and Conditions and privacy policy.</li>
                </ul>
                <li>Registration Process, in implementing DWA account registration, users are required to provide information which includes but is not limited to:</li>
                <ul className="dashed">
                  <li> Name;</li>
                  <li> Address;</li>
                  <li> Telephone Number;</li>
                  <li> E-mail;</li>
                  <li> Place and Date of Birth;</li>
                  <li> Employment;</li>
                  <li> TIN (optional);</li>
                  <li> KTP number or other identity (KITAP, KITAS and others); and</li>
                  <li> Information about the user's Bank account.</li>
                </ul>
                <li>DWA reserves the right to request information or other matters other than those stated in clause 2 as required in connection with the specified registration requirements. The new User hereby declares and guarantees that any data/explanation/document/information/statement provided relating to the registration process as a User is complete, original, correct and in accordance with the actual situation and constitutes the latest data/explanation/document/information/statement has not been modified and is still valid / has not expired and there are no changes or other conditions that have been agreed based on the provisions on the DWA Site registration page.</li>
                <li>The User hereby agrees that access to the DWA Account is effective after all requirements are fulfilled by the User and the registration process has gone through the verification process for approval by DWA. All risks arising in connection with closing/blocking/freezing DWA Account caused by User's error and/or negligence, will be the responsibility of the User and DWA will not provide compensation to User or other parties in any form for any claims/claims and compensation. loss from the User or any party in connection with the closing of the User's DWA Account.</li>
                <li>The User hereby agrees that access to the DWA Account is effective after all requirements are met by the User and the registration process has gone through the verification process for approval by DWA. All risks arising in connection with closing/blocking/freezing DWA Account caused by User's error and/or negligence, will be the responsibility of the User and DWA will not provide compensation to User or other parties in any form for any claims/claims and compensation. loss from the User or any party in connection with the closing of the User's DWA Account.</li>
                <li>The user agrees and authorizes DWA to use all data, explanations and information obtained by DWA regarding the User including but not limited to the use of the User's personal means of communication for all other purposes as long as possible and permitted by the applicable laws and regulations, including those intended for the marketing purposes of DWA or other parties collaborating with DWA. For the use of data that requires the approval of another party, the User states that he has given written consent to any third party for the use of the data, explanation and information, and therefore DWA will not provide compensation and/or liability in any form to the User and any party. for all risks, demands, lawsuits and/or compensation that may arise in the future related to the use of data, explanations and information that has obtained such written approval by DWA.</li>
                <li>Change of password or PIN, Users can reset their password by requesting to get a link in the registered email in order to enter a new password. Users can reset the PIN by entering the old PIN accompanied by entering a new PIN in the application. We cannot manually change or reset user passwords or PINs for the safety of all of our users.</li>
              </ol>

              <b><li>DWA ACCOUNT</li></b>
              <ol type="1">
                <li>Each DWA New Account will be administered by DWA based on a special identification according to the User's name which will also act as the name/identity of the DWA Account as stated on the User's identity. Users are prohibited from using a DWA Account other than the User's account, or accessing other DWA Accounts, or assisting others to gain unauthorized access to such accounts. All use of the DWA Account is the sole responsibility of the owner of the DWA Account recorded on the DWA Site.</li>
                <li>Users are required to use and include User's email and password that have been verified by DWA during the registration process. The DWA system will automatically deny access to the DWA Account, if it is not accompanied by the correct email and password. Users are responsible for maintaining the confidentiality of passwords, DWA Accounts, PINs, Wallet access, email login access and all types of activities that include transactions in the User's DWA Account. Users are fully responsible for the use of passwords and DWA Accounts. If there is a use of a password and/or DWA Account without the User's permission and other alleged violations occur, the User is obliged to contact DWA by sending an e-mail to info@dwa.network with supporting information. DWA is not responsible for any losses caused by misuse of the DWA Account and/or password, with or without the knowledge of the User.</li>
                <li>Users agree that they will not use the Service to commit criminal acts of any kind, including but not limited to, money laundering, gambling, buying illegal goods, terrorist activities or hacking activities. Any User who violates these rules can be terminated and must be responsible for the losses suffered by DWA or other Users on the DWA Site. DWA has the right to close the DWA Account and freeze funds, Crypto Assets and transactions in it if it finds suspicious activity in the DWA Account for an indefinite period of time.</li>
              </ol>

              <b><li>SCOPE OF SERVICES</li></b>
              <p className="pl-4" style={{ marginBottom : 0}}>The scope of services on the DWA site that can be used by users are as follows:</p>
              <ol type="1">
                <li>Purchase and sale of crypto assets according to the list of crypto assets traded;</li>
                <li>Receive fund placements and make fund withdrawals in USDT;</li>
                <li>Exchange between crypto assets against fellow users on the DWA site;</li>
                <li>Sending crypto assets to other users on the DWA site, as well as users of crypto assets outside the DWA site in accordance with the list of crypto assets traded;</li>
                <li>Accept deposits in USDT according to the list of traded crypto assets.</li>
              </ol>

              <b><li>TECHNICAL CRYPTO ASSET SYSTEMS</li></b>
              <ol type="1">
                <li>Your Wallet allows you to send, receive and store Crypto Assets, by providing instructions through the DWA Site;</li>
                <li>We will process the transaction according to the instructions we receive from you. You must verify all transaction information before sending us instructions. We do not guarantee the identity of the user, recipient, applicant or other party. Transactions cannot be canceled once submitted to the relevant Crypto Asset network;</li>
                <li>After being submitted to the Crypto Asset network, the User will wait for confirmation from the Crypto Asset network for a certain period of time. Incomplete transactions while in pending status will be properly settled, and will not be included in your Wallet balance or available to make transactions;</li>
                <li>We may charge network fees for processing transactions on your behalf. We will calculate the network fee at our discretion, although we will always notify you of the network fee on or before the time you authorize the transaction. A full list of DWA fees can be found on the Pricing and Fees page;</li>
                <li>We may refuse to process or transact your transactions as required by law or courts or other authorities to which DWA is subject;</li>
                <li>Our Crypto Asset Service is only available in connection with Crypto Assets that are supported and registered on the DWA Site, and this may change from time to time. Under no circumstances should you use the Wallet to store, send, request or receive Crypto Assets in any form that We do not support. We are not responsible or liable in connection with any attempt to use your Wallet for Crypto Assets that we do not support. If you have questions about the Crypto Assets we currently support, visit https://dwa.network/;</li>
                <li>We own or control the software protocols or regulate the operation of the Crypto Assets supported on the DWA Site: generally, extensible protocols are sourced and everyone uses, does not modify and distributes. We are not responsible for their operation and we cannot guarantee their functionality, safety or availability;</li>
                <li>You acknowledge and accept the risk that the software protocol used by any Crypto Asset you store in your Wallet may change. In particular, protocols are subject to such special rules. those rules may be materially regulated, and/or affecting the value of, and/or A Crypto that you Your Wallet If possible, we may give you a notice or warning on a fork in accordance with the Notice on these Terms and Conditions and you should read the notice that you receive from Us so that you can consider how to deal with future forks. However, it is your responsibility to make yourself aware of, and consider how to deal with, the upcoming fork. In the case of a fork, there is a risk that We may require a temporary cessation of operations in the event of a fork without prior notice to you. We may, in our possibility refuse to support one or both of the forks;</li>
                <li>You acknowledge the risk presented by the fork and you accept that we have no responsibility to assist you in moving or selling unsupported forked protocol branches.</li>
              </ol>

              <b><li>TECHNICAL CRYPTO ASSET SYSTEMS</li></b>
              <ol type="1">
                <li>Your Wallet allows you to send, receive and store Crypto Assets, by providing instructions through the DWA Site;</li>
                <li>We will process the transaction according to the instructions we receive from you. You must verify all transaction information before sending us instructions. We do not guarantee the identity of the user, recipient, applicant or other party. Transactions cannot be canceled once submitted to the relevant Crypto Asset network;</li>
                <li>After being submitted to the Crypto Asset network, the User will wait for confirmation from the Crypto Asset network for a certain period of time. Incomplete transactions while in pending status will be properly settled, and will not be included in your Wallet balance or available to make transactions;</li>
                <li>We may charge network fees for processing transactions on your behalf. We will calculate the network fee at our discretion, although we will always notify you of the network fee on or before the time you authorize the transaction. A full list of DWA fees can be found on the Pricing and Fees page;</li>
                <li>We may refuse to process or transact your transactions as required by law or courts or other authorities to which DWA is subject;</li>
                <li>Our Crypto Asset Service is only available in connection with Crypto Assets that are supported and registered on the DWA Site, and this may change from time to time. Under no circumstances should you use the Wallet to store, send, request or receive Crypto Assets in any form that We do not support. We are not responsible or liable in connection with any attempt to use your Wallet for Crypto Assets that we do not support. If you have questions about the Crypto Assets we currently support, visit https://dwa.network/.</li>
                <li>We own or control the software protocols or regulate the operation of the Crypto Assets supported on the DWA Site: generally, extensible protocols are sourced and everyone uses, does not modify and distributes. We are not responsible for their operation and we cannot guarantee their functionality, safety or availability.</li>
                <li>You acknowledge and accept the risk that the software protocol used by any Crypto Asset you store in your Wallet may change. In particular, protocols are subject to such special rules. those rules may be materially regulated, and/or affecting the value of, and/or A Crypto that you Your Wallet If possible, we may give you a notice or warning on a fork in accordance with the Notice on these Terms and Conditions and you should read the notice that you receive from Us so that you can consider how to deal with future forks. However, it is your responsibility to make yourself aware of, and consider how to deal with, the upcoming fork. In the case of a fork, there is a risk that We may require a temporary cessation of operations in the event of a fork without prior notice to you. We may, in our possibility refuse to support one or both of the forks.</li>
                <li>You acknowledge the risk presented by the fork and you accept that we have no responsibility to assist you in moving or selling unsupported forked protocol branches.</li>
              </ol>

              <b><li>RESPONSE, QUESTION, COMPLAINTS, AND TROUBLESHOOTING</li></b>
              <ol type="1">
                <li>If you have feedback, questions, complaints or complaints that you want to convey, contact us via our customer support website page at https://info@dwa.network. When you contact us, please provide your name, registered user id/email address, and other information we need to identify you, your DWA Account, and the transaction from which you received feedback, questions or complaints.</li>
                <li>If you wish to submit a complaint, you are expected to state the cause of the complaint, how you would like us to resolve the complaint and any other information you believe is relevant. We will acknowledge receipt of your complaint if you contact us via our customer support web page. The Customer Complaints Officer ("Officer") will consider your complaint. The officer will consider your complaint without prejudice based on the information you provide and any information provided by DWA. Within 15 (fifteen) working days of receipt of your complaint, the Officer will address all points raised in your complaint by sending an e-mail ("Resolution Notice") in which the Officer will: (i) offer to resolve your complaint by what you ask; (ii) make a determination to reject your complaint and explain the reasons for refusal; or (iii) offer to resolve your complaint with an alternative solution. In exceptional circumstances, if the Officer is unable to respond to your complaint within 15 (fifteen) working days for reasons beyond DWA's control, the Officer will send a reply indicating the reason for the delay in responding to your complaint and specifying the time limit used by the Officer. will respond to your complaint (which is no later than 35 (thirty five) working days since we received your complaint.</li>
              </ol>

              <b><li>REPRESENTATIONS AND WARRANTIES</li></b>
              <ol type="1">
                <li>All services on the DWA Site do not provide any guarantee or warranty and DWA does not guarantee that the DWA Site will always be accessible at any time due to disturbances or periodic system and network maintenance.</li>
                <li>The user declares and guarantees that he will use the Service properly and responsibly and not take any actions that are contrary to the laws, laws and regulations in force in the territory of the Republic of Indonesia.</li>
                <li>The user represents and guarantees that he will not use the Service in trading Crypto Assets related to: (i) narcotics, materials and/or chemical substances for research; (ii) money and anything similar to money, including derivatives; (iii) goods and/or services that violate Intellectual Property Rights; (iv) ammunition, firearms, explosives, sharp weapons in accordance with the applicable legal provisions; (v) goods and/or services that reveal the personal information of a third party that violates the law; (vi) goods and/or services related to lottery purchases, lay-aways; (vii) services related to banking outside the territory of the Republic of Indonesia; (viii) Card payments; (ix) Credit settlement and/or; (x) support of organizations prohibited or prohibited by the government.</li>
                <li>The user represents and warrants not to use the Service for acts involved in the practice of gambling and/or other activities that charge entry fees and promise prizes, including but not limited to casino games, gambling in sports, businesses that facilitate gambling and lottery methods.</li>
                <li>The User represents and warrants that all data, information and documents provided by the User to DWA are real, legal, honest, transparent, complete and accurate data, information and documents. The User states that he is willing to be prosecuted criminally or be sued in a civil manner if DWA knows or obtains information from any party that the data, information and documents provided by the User are not true/not completely true/false. Users are willing to update data/information (profile update) if at any time requested by DWA and then all documents that have been provided become the property of DWA.</li>
                <li>DWA has provided sufficient information and explanation regarding the DWA service that will be used by the User in accordance with the provisions of the Terms and Conditions and the User has understood and understood and is willing to bear all the consequences that may arise in connection with the use of DWA services including the benefits, risks and costs. costs attached to services and services.</li>
                <li>The User hereby agrees and authorizes DWA to use all data, information and information obtained by DWA regarding the User including but not limited to the use of the User's personal means of communication for all other purposes as long as it is possible and permitted by applicable laws, including which aims to market DWA or pi . products</li>
              </ol>

              <b><li>RESPONSIBILITY</li></b>
              <ol type="1">
                <li>The User agrees to bear any risks, losses or consequences suffered by the User caused by, among others:</li>
                <ul className="dashed">
                  <li> damage, delay, loss or error in sending orders and communications, electronically caused by the User;</li>
                  <li> DWA Account reports or DWA service usage notices sent to Users are received or read or misused by parties who are not authorized to DWA Accounts,</li>
                  <li> DWA Account Password is known by other people/parties due to User error.</li>
                </ul>
                <li>The User understands and agrees that the User will use the DWA Account and Service for transactions that do not conflict with the provisions of the legislation and/or DWA's internal policies that apply and/or other regulations that apply nationally and internationally related to the implementation the transaction, either directly or indirectly, and DWA will not provide compensation and/or liability of any kind to the User or any party for any claims and/or demands and/or losses arising in connection with the use of the Service by the User for transactions involving categorized as suspicious transactions and/or transactions that are prohibited by the provisions of the legislation and/or DWA's internal policies that apply and/or other regulations that apply both nationally and internationally related to transaction activities carried out by the User. directly or indirectly.</li>
                <li>In conducting transactions using the DWA Site, the User understands and agrees that there are certain sanctions imposed by the government, including the government of other countries, and/or other authorized agencies against several countries, entities and individuals. Referring to this, DWA has the right not to carry out/process transactions that are in violation of the provisions of these sanctions, and the competent authority may require the disclosure of related information. DWA is not responsible if DWA or other parties fail or delay the execution of transactions, or if there is disclosure of information as a result of direct or indirect violations of the provisions of these sanctions.</li>
              </ol>

              <b><li>RISK</li></b>
              <p className="pl-4" style={{ marginBottom : 0}}>Without reducing the meaning of the provisions regarding the prohibitions contained in the Terms and Conditions, the User is prohibited from doing the following:</p>
              <ol type="1">
                <li>Users are not allowed to take actions that can cause harm to DWA and or that are contrary to the Terms and Conditions and the applicable laws and regulations.</li>
                <li>If the User violates one or more of the provisions in this provision, the User will be fully responsible and hereby agrees that for such violation DWA has the right to limit the features of using DWA services until the closure of the DWA Account and the User acknowledges and agrees that DWA will not provide compensation. and or liability in any form to the User or any party for all risks or consequences arising from the limitation of service use and closing of the DWA Account. The user is further obliged to pay compensation to DWA in the amount of the loss that DWA may suffer for the violation. The User hereby authorizes DWA to debit the User Account for the payment of such compensation. In the event that the Funds in the User Account are not available and or insufficient, then the User is obliged to return all the Funds to DWA within 7 (seven) working days after DWA submits the said claim.</li>
              </ol>

              <b><li>SECRET INFORMATION</li></b>
              <ol type="1">
                <li>Any information and or data provided by DWA to the User and or data obtained by the User as the implementation of the Terms and Conditions either given or delivered orally, in writing, graphically or delivered through electronic media or information and or data in other forms during the course of the conversation or during other work is confidential (hereinafter referred to as “Confidential Information”).</li>
                <li>The User agrees and agrees that at all times he will keep confidential information obtained as a result of the cooperation with anyone or will not use it for the benefit of the User or the interests of other parties, without first obtaining written approval from the authorized official from DWA or other authorized parties. in accordance with the provisions of applicable law.</li>
                <li>If the User violates the provisions as referred to in points 1 and 2 of the provisions regarding this confidentiality, then all losses, demands and or claims experienced by DWA are the full responsibility of the User, and at the first request from DWA, the User is obliged to resolve them in accordance with legal provisions. and applicable laws and regulations and provide compensation that may arise as a result of the violation to DWA. The User hereby agrees that DWA will not provide compensation and or liability in any form to the User or any party for any claims and or claims that may arise in the future in connection with the violation.</li>
                <li>The obligation to keep confidential information as referred to in points 1 and 2 provisions regarding confidentiality becomes invalid, if:</li>
                <ul className="dashed">
                  <li> Such confidential information becomes available to the general public;</li>
                  <li> Such confidential information is ordered to be disclosed in compliance with an order by a court or other competent government agency;</li>
                  <li> Such confidential information is provided in accordance with applicable laws.</li>
                </ul>
                <li>The user further agrees to make every effort and take every necessary action to prevent third parties from gaining access to or resulting in the disclosure of confidential information.</li>
                <li>In the event that the cooperation has ended, the User agrees that the obligation to keep documents and materials which are classified as confidential information as regulated in this provision will remain in effect.</li>
              </ol>

              <b><li>NEGLIGENCE</li></b>
              <ol type="1">
                <li>The user agrees that what is meant by negligence is the following things or events:</li>
                <ul className="dashed">
                  <li> Negligence;</li>
                  <li> If the User is negligent in carrying out an obligation or violates a provision in the Terms and Conditions;</li>
                  <li> False Statements;</li>
                  <li> If it turns out that a statement or guarantee given by the User in the Terms and Conditions – is not true or does not correspond to the reality.</li>
                </ul>
                <li>In the event of an incident of negligence based on item 1 of this negligence provision by the User, DWA can choose whether to continue or close the User Account. If DWA wishes to close a User Account, such intention must be notified to the User within a reasonable time according to DWA.</li>
                <li>In the event of an incident of negligence by the User as referred to, DWA has the right to immediately deactivate the DWA Account without having to give prior notification to the User, and the User hereby agrees that DWA will not provide compensation and or liability in any form to the User. or any party for all claims and or claims and or claims and or requests for compensation from any party that may arise in connection with the occurrence of such negligence.</li>
              </ol>

              <b><li>TAX</li></b>
              <p className="pl-4" style={{ marginBottom : 0, lineHeight: '25px'}}>That the tax on Crypto Asset trading activities is a tax borne by each activity actor, in this case the User and DWA. Users can consult tax payments with a Personal Tax Consultant, and DWA does not bear User taxes unless otherwise specified in these Terms and Conditions.</p>

              <b><li>SECURITY</li></b>
              <p className="pl-4" style={{ marginBottom : 0, lineHeight: '25px'}}>DWA has implemented network and security measures as an information security network against unauthorized access in the use, change and/or disclosure of Accounts, with security standards that are in accordance with the provisions of the applicable laws and regulations. The entry of parties who are not responsible for access to the use, change and/or disclosure of accounts from third parties due to negligence and/or errors of the User results in the borne risk by the User, then DWA will not provide compensation and or liability in any form to the User or other parties. whatsoever for any risks, responsibilities and claims that may arise in connection with the User's negligence/intentional/error in providing information.</p>

              <b><li>FORCE MAJEUR</li></b>
              <ol type="1">
                <li>What is meant by Force Majeure are events that occur beyond the ability and control of Tokocrypto so as to affect the implementation of transactions, including but not limited to:</li>
                <ul className="dashed">
                  <li> Earthquakes, hurricanes, floods, landslides, volcanic eruptions and other natural disasters;</li>
                  <li> War, demonstrations, riots, terrorism, sabotage, embargoes and mass strikes;</li>
                  <li> Economic policies from the Government that directly affect it.</li>
                </ul>
                <li>As long as DWA has carried out all of its obligations in accordance with the applicable laws and regulations in connection with the occurrence of the Force Majeure, DWA will not provide compensation and or liability in any form to the User or any other party for any risks, responsibilities and demands. which may arise in connection with the delay or non-performance of obligations due to Force Majeure.</li>
              </ol>

              <b><li>LIMITATION OF LIABILITY</li></b>
              <ol type="1">
                <li>DWA always strives to keep the Services safe, convenient and functioning properly, but we cannot guarantee continuous operation or that access to our Services is always perfect. It is possible that the information and data on the DWA website is not available in real time.</li>
                <li>DWA does not warrant that the results obtained from the use of the service will be accurate or reliable.</li>
                <li>The User agrees that the User uses the DWA Service at the User's own risk, and that the DWA Service is provided to the User on an "AS IS" and "AS AVAILABLE" basis.</li>
                <li>To the extent permitted by applicable law, DWA (including the Parent Company, directors and employees) shall not be liable, and User agrees not to hold DWA liable, for any damage or loss (including but not limited to loss of money, reputation, profits, or other intangible losses) which are directly or indirectly caused by the following:</li>
                <ul className="dashed">
                  <li> User's inability to use DWA services is at personal risk;</li>
                  <li> Loss of Use, Loss of Profit, Loss of Revenue, Loss of Data, Loss of Goodwill, or Failure to realize the expected savings, in any case directly or indirectly;</li>
                  <li> Any losses caused by the negligence of the User including but not limited to failure to log in via a third party device and/or failure to maintain the confidentiality of the device used for login;</li>
                  <li> Condition and quality of tradable products or Crypto Assets;</li>
                  <li> Intellectual Property Rights Infringement;</li>
                  <li> Disputes between Users;</li>
                  <li> Defamation of other parties;</li>
                  <li> Any misuse of Assets purchased by the User;</li>
                  <li> Viruses or other malware (bots, scripts, automation tools) obtained by accessing or connecting to the Service;</li>
                  <li> Skimming or hacking processes, which cause harm to Users on DWA Services;</li>
                  <li> Crashes, bugs, errors or inaccuracies in the Service;</li>
                  <li> Damage to your hardware from using any Service;</li>
                  <li> Enforcement actions taken with respect to User accounts;</li>
                  <li> Any hacking done by a third party on the User's account.</li>
                </ul>
                <li>The User acknowledges and agrees that the User's only right in connection with any problems or dissatisfaction with the service is to stop using the Service.</li>
              </ol>

              <b><li>ANNOUNCEMENT</li></b>
              <ol type="1">
                <li>The User expressly agrees to communicate with DWA in electronic format, and agrees that all terms, conditions, agreements, notices, disclosures or any other form of communication provided by DWA to Users electronically shall be deemed to be in writing.</li>
                <li>The user agrees to receive emails from the DWA Site. E-mails sent may contain information about accounts, transactions, systems, promotions and so on.</li>
              </ol>
              <p className="pl-4" style={{ marginBottom : 0, lineHeight: '25px'}}>I declare that I have accepted and read the “TERMS AND CONDITIONS” USER AGREEMENT understand and agree to its contents.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
